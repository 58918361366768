<template>
    <div class="nhb-container">
        <el-image v-if="showPhoneBtn" class="nhb-phone" @click="phoneClick" src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/142f7c0e7a11767333792c434bbedba2.png"></el-image>
        <el-button class="nh-btn" :size="$getComponentSize()" :type="type" @click="doClick" :disabled="disabled"><slot/></el-button>
    </div>
</template>
<script>
export default {
    name: 'nh-button',
    props: {
        type: {
            type: String,
            default: 'warning'
        },
        disabled: Boolean,
        showPhoneBtn: Boolean
    },
    data () {
        return {
        }
    },
    methods: {
        doClick() {
            this.$emit('click')
        },
        phoneClick() {
            this.$emit('make-phone-call')
        }
    }
}
</script>
<style>
    .nh-btn {
        background: #5587FF;
        box-shadow: inset 0 1px 3px 0 #28407C;
        border-radius: 10px;
        flex: 1;
    }
    .nhb-container {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    .nhb-phone {
        width: 35px;
        height: 35px;
        margin: 0 20px;
        cursor: pointer;
    }
</style>