<!--
TODO 发出全局选中事件
-->
<template>
    <div v-if="show">
        <div class="sd_wrapper">
            <div class="sd_label">{{property.name}}</div>
            <div class="sd_line"></div>
            <div class="sd_select">
                <SelectDatasourcePicker v-if="property.selectDatasource" :sourceKey="property.selectDatasource"
                                        :multiple="true" v-model="val" :property="property"></SelectDatasourcePicker>
                <el-select v-else v-model="val" placeholder="请选择" multiple
                           :disabled="property.ro || property.propertyData.immutable" @change="doChange">
                    <el-option
                            v-for="(item) in property.options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-if="showOtherInput" class="sd_wrapper">
            <el-input class="input_inner_input" v-model="otherVal" type="text" :placeholder="otherPlaceholder"></el-input>
        </div>
    </div>
</template>

<script>
import SelectDatasourcePicker from '../singleSelect/SelectDatasourcePicker.vue'
import surveyUtil from "@/common/surveyUtil";
export default {
    name: '',
    components: {
        SelectDatasourcePicker
    },
    data() {
        return {
            val: this.value,
            showOtherInput: false,
            otherVal: this.otherValue,
            otherPlaceholder: '',
            show: !this.property.hidden
        }
    },
    props: {
        property: {
            type: Object,
            required: true
        },
        value: {
            required: false
        },
        otherValue: {
            required: false
        },
        propertyIndex: {
            type: Number,
            required: false
        }
    },
    watch: {
        value(optionIds) {
            console.log(`watch optionIds:${optionIds}`)
            this.val = optionIds
        },
        val(optionIds) {
            this.$emit('update:value', optionIds)
        },
        otherValue(val) {
            this.otherVal = val
        },
        otherVal(val) {
            // console.log(val)
            this.$emit('update:otherValue', val)
        }
    },
    created() {
        this.doSetup(this.value)
        this.$globalEvent.$on('selectChanged', (property)=>{
            // console.log('selectChanged on')
            setTimeout(()=>{
                const show = surveyUtil.showProperty(this.property, property)
                if (show === 1) {
                    this.show = true
                    this.property.propertyData.hidden = !this.show
                }
                if (show === 2) {
                    this.show = false
                    this.property.propertyData.hidden = !this.show
                }
            },100)

        })
    },
    methods: {
        doChange(selectedValues) {
            let otherSelected = false
            for (const selectedValue of selectedValues) {
                const option = this.findOptionByOptionId(selectedValue)
                if (option.other) {
                    otherSelected = true
                    this.otherPlaceholder = option.name
                    break
                }
            }
            this.showOtherInput = otherSelected

        },
        findOptionByOptionId(optionId) {
            if (this.property.options) {
                const opt = this.property.options.find(option => option.id === optionId)
                return opt
            }
            return undefined
        },
        doSetup(optionIds) {
            if (!optionIds || optionIds.length <= 0)  {
                if (this.property.parsedDefaultValue) {
                    this.val = this.property.parsedDefaultValue
                }
            }
            if(this.val) {
                this.doChange(this.val)
            }
        }
    }
}
</script>

<style scoped>
.sd_wrapper {
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    min-height: 30px;
    font-size: 12px;
    color: #1B243F;
    letter-spacing: 0.89px;
    align-items: center;
}

.sd_label {
    padding-left: 10px;
    /*width: 87px;*/
    /*min-width: 87px;*/
}

.sd_line {
    height: 23px;
    width: 1px;
    background: #656D72;
    margin: 0 9px;
    min-width: 1px;
}

.sd_select {
    display: flex;
    align-items: center;
}

.sd_select,
.sd_picker,
.sd_input {
    min-height: 30px;
    line-height: 30px;
    flex: 1;
}

.sd_readonly {
    color: lightgray;
}
.sd_select >>> .el-select {
    width: 100%;
}
.sd_select >>> .el-input__inner {
    border: unset;
    height: 30px;
}
</style>
