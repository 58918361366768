<template>
    <div class="input_wrapper" v-if="show">
        <div class="input_label">{{ property.name }}</div>
        <div class="input_line"></div>
        <div class="input_input">
            <el-input class="input_inner_input" v-model="val" @blur="doBlur" :disabled="property.ro || (property.propertyData && property.propertyData.immutable)">
                <template v-if="property.suffix" slot="append">{{ property.suffix }}</template>
            </el-input>
        </div>
    </div>
</template>

<script>
import surveyUtil from "@/common/surveyUtil";

export default {
    name: '',
    components: {},
    data() {
        return {
            val: this.value,
            show: !this.property.hidden,
            originVal: this.value
        }
    },
    props: {
        property: {
            type: Object,
            required: true
        },
        value: {
            required: false
        },
        propertyIndex: {
            type: Number,
            required: false
        },
        surveyDataId:{
            type:String,
            required:false
        }
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            // console.log(val)
            this.$emit('update:value', val)
        }
    },
    computed: {
        displayType() {
            let type = 'text'
            if (this.property.displayType === this.$constant.surveyPropertyInputDisplayType.number
                || this.property.displayType === this.$constant.surveyPropertyInputDisplayType.mobile) {
                type = 'number'
            } else if (this.property.displayType === this.$constant.surveyPropertyInputDisplayType.digit) {
                type = 'digit'
            }
            console.log(type)
            return type
        }
    },
    created() {
        this.$globalEvent.$on('selectChanged', (property)=>{
            // console.log('selectChanged on')
            setTimeout(()=>{
                const show = surveyUtil.showProperty(this.property, property)
                if (show === 1) {
                    this.show = true
                    this.property.propertyData.hidden = !this.show
                }
                if (show === 2) {
                    this.show = false
                    this.property.propertyData.hidden = !this.show
                }
            },100)

        })
    },
    methods: {
        doBlur() {
            console.log('originVal:' + this.originVal)
            if (this.val && this.property.blurAction) {
                const params = {
                    value: this.val,
                    property: this.property,
                    originVal: this.originVal,
                    surveyDataId: this.surveyDataId
                }
                this.$httpUtil.post(decodeURIComponent(this.property.blurAction), params, res => {
                    const resultData = res.data
                    if (!resultData.result) {
                        this.$alert(resultData.message,
                            {
                                showClose: false,
                                showCancelButton: false
                            }).then(() => {
                                this.$router.back()
                        })
                    }
                }, this)
            }
        }
    }
}
</script>

<style scoped>
.input_wrapper {
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    height: 30px;
    font-size: 12px;
    align-items: center;
    font-family: Helvetica;
    color: #1B243F;
    letter-spacing: 0.89px;
    text-align: center;
}

.input_label {
    padding-left: 10px;
    /*width: 87px;*/
    line-height: 16px;
}

.input_line {
    height: 23px;
    width: 1px;
    background: #656D72;
    margin: 0 9px;
}

.input_input, .input_inner_input {
    height: 100%;
    flex: 1;
    border: unset;
    background-color: transparent;
}

.input_inner_input {
    width: 100%;
}

.input_inner_input >>> .el-input__inner {
    height: 30px;
    border: unset;
}
</style>
